<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="center" class="my-2">
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Partner Name" rules="required|min:2|max:30">
        <v-text-field v-model="siteData.managing_partner_name" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.name')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Partner Email" rules="required|email">
        <v-text-field v-model="siteData.managing_partner_email" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.email')"
                      dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Phone Number" rules="required">
        <v-text-field v-model="siteData.managing_partner_phone_number" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.phoneNumber')" dense outlined/>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep5Content',
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    }
  }
}
</script>
